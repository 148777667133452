/* You can add global styles to this file, and also import other style files */
body {
  font-family: "Poppins", sans-serif;
}
.full-img {
  width: 100%;
  height: auto;
  display: block;
}
@media (min-width: 1200px) {
  .container {
    max-width: 1310px !important;
  }
}

@media (max-width: 767px) {
  .row.row-grid > [class*="col-"] + [class*="col-"] {
    margin-top: 15px !important;
  }
  .navbar-transparent {
    position: static !important;
  }
  .navbar-transparent.headroom--not-top {
    position: fixed !important;
  }
  .section {
    position: relative;
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
}

